import { ApiMethods } from "@/features/api.methods";
import { ApiServiceInstance } from '@/features/api.service';
import { ApiUrls } from "@/features/apiUrls";
import { DailyEvent } from "@/models/client/driver/DailyEvent";
import { DailyEventGroup } from "@/models/client/driver/DailyEventGroup";
import { DriverState } from "@/models/client/driver/DriverState";
import { defineStore } from 'pinia';
import { ref } from "vue";

export const useDriverStateStore = defineStore('DriverState', () => {
    const currentDriverState = ref<DriverState>();
    const driverStates = ref<DriverState[]>();
    const driverDailyEventsGroup = ref<DailyEventGroup[]>([]);
    const driverDailyEvents = ref<DailyEvent[]>();
    const currentDriverId = ref<number>();

    async function currentState(driverId: number) {
        const result = await ApiServiceInstance.sendRequest<DriverState>(ApiMethods.GET, `${ApiUrls.GET_CURRENT_STATE}/${driverId}`);
        if (result.successResult) {
            currentDriverState.value = result.successResult;
        }
    }

    async function currentStateByDate(driverId: number, toDate: string) {
        const result = await ApiServiceInstance.sendRequest<DriverState>(
            ApiMethods.GET,
            `${ApiUrls.GET_CURRENT_STATE_BY_DATE}/${driverId}/by-date?toDate=${toDate}`
        );

        if (result.successResult) {
            currentDriverState.value = result.successResult;
        }
    }

    async function currentStateByDateRange(driverId: number, fromDate: string, toDate: string) {
        const result = await ApiServiceInstance.sendRequest<DriverState>(
            ApiMethods.GET,
            `${ApiUrls.GET_CURRENT_STATE_BY_DATE}/${driverId}/by-range?fromDate=${fromDate}&toDate=${toDate}`
        );

        if (result.successResult) {
            currentDriverState.value = result.successResult;
        }
    }

    async function currentStateByDateRangeNew(
        driverId: number,
        fromDate: string,
        toDate: string,
        sessionId: number,
        tabId: number,
        screen: number = 1738
    ) {

        const url = `${ApiUrls.GET_CURRENT_STATE_BY_DATE_NEW}/${driverId}/by-range` +
            `?fromDate=${encodeURIComponent(fromDate)}` +
            `&toDate=${encodeURIComponent(toDate)}` +
            `&sessionId=${sessionId}` +
            `&tabId=${tabId}` +
            `&screenResolution=${screen}`;

        const result = await ApiServiceInstance.sendRequest<DriverState>(ApiMethods.GET, url);

        if (result.successResult) {
            currentDriverState.value = result.successResult;
        }
    }

    type Optional = null | undefined;

    async function getAll(pageSize: number, pageNumber: number, carrierId?: number | Optional, searchText?: string | Optional, dutyStatus?: number | Optional, violations?: boolean | Optional, connected?: boolean | Optional) {
        let url = `${ApiUrls.GET_DRIVER_STATES}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
        if (searchText?.trim()) url += `&Search=${searchText.trim()}`;
        if (dutyStatus) url += `&DutyStatus=${dutyStatus}`;
        if (violations != null) url += `&Violations=${Boolean(violations).toString()}`;
        if (connected != null) url += `&ELD=${Boolean(connected).toString()}`;
        if (carrierId) url += `&CarrierId=${carrierId}`;

        const result = await ApiServiceInstance.sendRequest<DriverState[]>(ApiMethods.GET, url);
        if (result.successResult) {
            console.log("Driver states has come", result.successResult);
            driverStates.value = result.successResult;
        }
    }

    async function dailyEvents(model: DriverDateTimeRequest) {
        const result = await ApiServiceInstance.sendRequest<DailyEvent[]>(ApiMethods.POST, ApiUrls.GET_DRIVER_DAILY_EVENTS, model);
        if (result.successResult) {
            driverDailyEvents.value = result.successResult;
        }
    }

    async function dailyEventsGroup(model: DriverDateTimeRequest) {
        const result = await ApiServiceInstance.sendRequest<DailyEventGroup[]>(ApiMethods.POST, ApiUrls.GET_DRIVER_DAILY_EVENTS_GROUP_BY_DATE, model);
        if (result.successResult) {
            driverDailyEventsGroup.value = result.successResult;
        }
    }

    async function removeDailyEvent(id: number) {
        if (id !== null) {
            let result = await ApiServiceInstance.sendRequest<boolean>(ApiMethods.DELETE, `${ApiUrls.DELETE_ELD_EVENT}/${id}`);
        }
    }

    async function removeDailyEvents(ids: number[]) {
        const model: EldEventRemoveRequest = { eventIds: ids };
        if (ids && ids.length > 0) {
            const result = await ApiServiceInstance.sendRequest<boolean>(
                ApiMethods.POST,
                ApiUrls.DELETE_ELD_EVENTS,
                model
            );
            return result.successResult;
        }
    }

    function setCurrentDriverId(id: number) {
        currentDriverId.value = id;
    }

    function resetCurrentDriverState() {
        currentDriverState.value = undefined;
    }

    function resetDriverDailyEventsGroup() {
        driverDailyEventsGroup.value = [];
    }

    return {
        currentDriverState,
        driverStates,
        driverDailyEvents,
        driverDailyEventsGroup,
        currentDriverId,
        currentState,
        currentStateByDate,
        currentStateByDateRange,
        currentStateByDateRangeNew,
        getAll,
        dailyEvents,
        dailyEventsGroup,
        setCurrentDriverId,
        removeDailyEvent,
        removeDailyEvents,
        resetCurrentDriverState,
        resetDriverDailyEventsGroup
    }
});


interface EldEventRemoveRequest {
    eventIds: number[];
}
