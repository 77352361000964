import { useDriverStateStore } from '@/store/Client/Drivers/Drivers';
import { useHOSStore } from '@/store/HOS';
import { hasRoleAccess } from '@/utils/role.permission.helpers';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/components/general/MainLayout.vue'),
        children: [
            {
                path: 'hos/:id?',
                name: 'hos',
                meta: {
                    title: 'Hours of Service',
                },
                component: () => import('@/views/HOS.vue'),
            },
            {
                path: '403',
                name: 'AccessDenied',
                meta: {
                    title: 'Access Denied',
                },
                component: () => import('@/views/AccessDenied.vue'),
            },
            {
                path: 'log/:param?',
                name: 'log',
                component: () => import('@/views/Logs/Log.vue'),
            },
            {
                path: 'driver/daily/hos/:driverId/:date',
                name: 'driver-daily-hos',
                component: () => import('@/views/Drivers/DriverDailyHos/DriverDailyHos.vue'),
            },
            {
                path: 'driver/overview/:driverId?',
                name: 'driver-overview',
                component: () => import('@/views/Drivers/Drivers.vue'),
            },
            {
                path: 'driver/state/:driverId',
                name: 'driver-state',
                component: () => import('@/views/Drivers/DriverState.vue'),
            },
            {
                path: 'issuer-states',
                name: 'issuer-states',
                component: () => import('@/views/pages/IssuerState/IssuerState.vue'),
            },
            {
                path: 'issuer-states/manage/:id?',
                name: 'issuer-states-manage',
                component: () => import('@/views/pages/IssuerState/IssuerStateManage.vue'),
            },
            {
                path: 'hos-rules',
                name: 'hos-rules',
                component: () => import('@/views/pages/HOSRules/HOSRules.vue'),
            },
            {
                path: 'hos-rules/manage/:id?',
                name: 'hos-rules-manage',
                component: () => import('@/views/pages/HOSRules/HOSRulesManage.vue'),
            },
            {
                path: 'restarts',
                name: 'restarts',
                component: () => import('@/views/pages/Restart/Restart.vue'),
            },
            {
                path: 'restart/manage/:id?',
                name: 'restart-manage',
                component: () => import('@/views/pages/Restart/RestartManage.vue'),
            },
            {
                path: 'rest-breaks',
                name: 'rest-breaks',
                component: () => import('@/views/pages/RestBreak/RestBreak.vue'),
            },
            {
                path: 'routes',
                name: 'routes',
                component: () => import('@/views/pages/Routes/Routes.vue'),
            },
            {
                path: 'routes/:id',
                props: true,
                name: 'routesId',
                component: () => import('@/views/pages/Routes/RoutesId.vue'),
            },
            {
                path: 'rest-break/manage/:id?',
                name: 'rest-break-manage',
                component: () => import('@/views/pages/RestBreak/RestBreakManage.vue'),
            },
            {
                path: 'cargo-types',
                name: 'cargo-types',
                component: () => import('@/views/pages/CargoType/CargoType.vue'),
            },
            {
                path: 'cargo-type/manage/:id?',
                name: 'cargo-type-manage',
                component: () => import('@/views/pages/CargoType/CargoTypeManage.vue'),
            },
            {
                path: 'vehicle-fuels',
                name: 'vehicle-fuels',
                component: () => import('@/views/pages/VehicleFuel/VehicleFuel.vue'),
            },
            {
                path: 'vehicle-fuel/manage/:id?',
                name: 'vehicle-fuel-manage',
                component: () => import('@/views/pages/VehicleFuel/VehicleFuelManage.vue'),
            },
            {
                path: 'eld-connection-interfaces',
                name: 'eld-connection-interfaces',
                component: () => import('@/views/pages/EldConnectionInterface/EldConnectionInterface.vue'),
            },
            {
                path: 'eld-connection-interface/manage/:id?',
                name: 'eld-connection-interface-manage',
                component: () => import('@/views/pages/EldConnectionInterface/EldConnectionInterfaceManage.vue'),
            },
            {
                path: 'vehicles',
                name: 'vehicles',
                component: () => import('@/views/pages/Vehicle/Vehicle.vue'),
            },
            {
                path: 'vehicle/manage/:id?',
                name: 'vehicle-manage',
                component: () => import('@/views/pages/Vehicle/VehicleManage.vue'),
            },
            {
                path: 'drivers',
                name: 'drivers',
                component: () => import('@/views/pages/Driver/Driver.vue'),
            },
            {
                path: 'driver/manage/:id?',
                name: 'driver-manage',
                component: () => import('@/views/pages/Driver/DriverManage.vue'),
            },
            {
                path: 'users',
                name: 'users',
                component: () => import('@/views/pages/User/User.vue'),
            },
            {
                path: 'user/manage/:id?',
                name: 'user-manage',
                component: () => import('@/views/pages/User/UserManage.vue'),
            },
            {
                path: 'permissions',
                name: 'permissions',
                component: () => import('@/views/pages/Permission/Permission.vue'),
            },
            {
                path: 'permission/manage/:id?',
                name: 'permission-manage',
                component: () => import('@/views/pages/Permission/PermissionManage.vue'),
            },
            {
                path: 'roles',
                name: 'roles',
                component: () => import('@/views/pages/Role/Role.vue'),
            },
            {
                path: 'role/manage/:id?',
                name: 'role-manage',
                component: () => import('@/views/pages/Role/RoleManage.vue'),
            },
            {
                path: 'providers',
                name: 'providers',
                component: () => import('@/views/pages/Provider/Provider.vue'),
            },
            {
                path: 'provider/manage/:id?',
                name: 'provider-manage',
                component: () => import('@/views/pages/Provider/ProviderManage.vue'),
            },
            {
                path: 'carriers/list',
                name: 'carriers-list',
                component: () => import('@/views/pages/Carrier/CarriersList.vue'),
            },
            {
                path: 'carrier/info',
                name: 'carrier-info',
                component: () => import('@/views/pages/Carrier/CarrierInfo.vue'),
            },
            {
                path: 'carrier/manage/:id?',
                name: 'carrier-manage',
                component: () => import('@/views/pages/Carrier/CarrierManage.vue'),
            },
            {
                path: 'dvirs',
                name: 'dvirs',
                component: () => import('@/views/pages/DVIRs/DVIRs.vue'),
            },
            {
                path: 'dvir/manage/:id?',
                name: 'dvir-manage',
                component: () => import('@/views/pages/DVIRs/DVIRManage.vue'),
            },
            {
                path: 'unidentified/events',
                name: 'unidentified-events',
                component: () => import('@/views/EldEvents/UnidentifiedEvents.vue'),
            },
            {
                path: 'assign/unidentified/event/:id?',
                name: 'assign-unidentified-event',
                component: () => import('@/views/EldEvents/AssignUnidentifiedEvent.vue'),
            },
            {
                path: 'idling',
                name: 'idling',
                component: () => import('@/views/Reports/Idling.vue'),
            },
            {
                path: 'reminders',
                name: 'reminders',
                component: () => import('@/views/Maintenance/Reminders/Reminders.vue'),
            },
            {
                path: 'reminder/manage/:id?',
                name: 'reminder-manage',
                component: () => import('@/views/Maintenance/Reminders/ReminderManage.vue'),
            },
            {
                path: 'maintenance/history',
                name: 'maintenance-history',
                component: () => import('@/views/Maintenance/MaintenanceHistory.vue'),
            },
            {
                path: 'maintenance/manage/:id?',
                name: 'maintenance-manage',
                component: () => import('@/views/Maintenance/MaintenanceManage.vue'),
            },
            {
                path: 'maintenance/service/types',
                name: 'maintenance-service-types',
                component: () => import('@/views/pages/MaintenanceServiceType/ServiceType.vue'),
            },
            {
                path: 'maintenance/service/type/manage/:id?',
                name: 'maintenance-service-type-manage',
                component: () => import('@/views/pages/MaintenanceServiceType/ServiceTypeManage.vue'),
            },
            {
                path: 'eld/info',
                name: 'eld-info',
                component: () => import('@/views/ELDs/Elds.vue'),
            },
            {
                path: 'details',
                name: 'details',
                component: () => import('@/views/Edit/MainWindow.vue'),
            },
            {
                path: 'details-new',
                name: 'details-new',
                component: () => import('@/views/new/MainWindowNew.vue'),
            },
            {
                path: 'deletion/menu',
                name: 'deletion-menu',
                // component: () => import('@/views/Edit/DeletionMenu.vue'),
                component: () => import('@/views/new/DeletionMenuNew.vue'),
            },
            {
                path: 'activity/menu',
                name: 'activity-menu',
                component: () => import('@/views/Edit/Activity.vue'),
            },
            {
                path: 'ifta',
                name: 'ifta',
                component: () => import('@/views/pages/Ifta/index.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login.vue')
    },
    {
        path: '/carrier/navigate',
        name: 'carrier-navigate',
        component: () => import('@/views/Carriers/CarrierNavigate.vue'),
    },
    {
        path: '/password-reset/:token',
        name: 'password-reset',
        component: () => import('@/views/pages/Driver/DriverPasswordReset.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const driverStateStore = useDriverStateStore();
    const hosStore = useHOSStore();

    let token = cookies.get('token');

    if (to.name === 'password-reset') {
        return next();
    }

    if (from.name === 'login' && !hasRoleAccess('ProviderAdmin') && !hasRoleAccess('AppAdmin')) {
        router.go(0);
    }

    if (to.name !== 'login' && !token) {
        return next({ name: 'login' })
    } else {
        if (token && to.name === 'login') {
            return next('/')
        } else {
            if (!(to.name as string).includes("details") || !(to.name as string).includes("log") || !(to.name as string).includes("driver-overview") || !(to.name as string).includes('details-new')) {
                driverStateStore.resetCurrentDriverState();
                driverStateStore.resetDriverDailyEventsGroup();
                hosStore.resetDutyTimes();
            }
            return next()
        }
    }
});

export default router;
